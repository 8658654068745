<template>
  <div class="public-donation-page">
    <public-donation-info />

    <!-- For district campaigns (non–donation page type) -->
    <school-detail
      v-if="getCampaignType === 'district' && !getDonationPageType"
    ></school-detail>

    <!-- For student type (not parent) -->
    <wizfit-video-progress
      v-if="isStudent && !isParent"
    ></wizfit-video-progress>

    <!-- For parent route -->
    <student-card-goal v-if="isParent"></student-card-goal>

    <!-- For student type -->
    <prizes-won v-if="isStudent"></prizes-won>
    <video-section v-if="isStudent"></video-section>

    <!-- For parent route -->
    <student-card v-if="isParent"></student-card>
    <v-container v-if="isParent">
      <v-row>
        <v-col cols="12" sm="8">
          <intro-video-pdp></intro-video-pdp>
        </v-col>
      </v-row>
    </v-container>

    <!-- Recent donation shown for student or teacher -->
    <recent-donation
      v-if="isStudent || isTeacher"
      
    ></recent-donation>

    <sponsors-detail></sponsors-detail>
    <wizfit-gallery></wizfit-gallery>
    <leaderboard-table v-if="!excludedSchool"></leaderboard-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PublicDonationPage",
  components: {
    PublicDonationInfo: () => import("./PublicDonationInfo/PublicDonationInfo"),
    LeaderboardTable: () =>
      import(
        "../../components/PublicDonationPages/LeaderboardTable/LeaderboardTable"
      ),
    SponsorsDetail: () =>
      import("../../components/PublicDonationPages/Sponsors/SponsorsDetail"),
    SchoolDetail: () => import("./SchoolDetaill/SchoolDetail"),
    WizfitGallery: () => import("./WizfitGallery/WizfitGallery"),
    RecentDonation: () => import("./RecentDonation/RecentDonation"),
    PrizesWon: () => import("./PrizesWon/PrizesWon"),
    WizfitVideoProgress: () =>
      import("./WizfitVideoProgress/WizfitVideoProgress"),
    StudentCard: () => import("./StudentCards/StudentCards"),
    StudentCardGoal: () => import("./StudentCards/StudentCardsGoal"),
    VideoSection: () =>
      import("@/components/PublicDonationPages/VideoSection/VideoSection"),
    IntroVideoPdp: () =>
      import("@/components/PublicDonationPages/IntroVideoPdp/IntroVideoPdp"),
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignType() {
      return this.getCampaign.campaign_detail.campaign_type;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
    // Define a computed property for parent routes based on the route path
    isParent() {
      return this.$route.matched[0].path.substring(1) === "parent";
    },
    isStudent() {
      return (
        this.getDonationPageType && this.getDonationPageType.type === "student"
      );
    },
    isTeacher() {
      return (
        this.getDonationPageType && this.getDonationPageType.type === "teacher"
      );
    },
    // Exclude schools with IDs 177,178,179,180
    excludedSchool() {
      const schoolId = this.getCampaign.campaign_detail.school;
      return [177, 178, 179, 180].includes(schoolId);
    },
  },
  methods: {
    updateMetaTags() {
  if (!this.getCampaign || !this.getCampaign.student_detail) return;

  const title = `Support ${this.getCampaign.student_detail.student_first_name} in their campaign!`;
  const description = `Help ${this.getCampaign.student_detail.student_first_name} reach their goal in this amazing campaign!`;
  const imageUrl = this.getCampaign.student_detail.student_logo;

  const ogTitle = document.getElementById("og-title");
  const ogDescription = document.getElementById("og-description");
  const ogImage = document.getElementById("og-image");

  if (ogTitle) ogTitle.setAttribute("content", title);
  if (ogDescription) ogDescription.setAttribute("content", description);
  if (ogImage) ogImage.setAttribute("content", imageUrl);
},
  },
  mounted() {
    this.updateMetaTags();
  },
};
</script>

<style scoped>
.public-donation-page {
  padding: 0px;
  max-width: 100vw;
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .public-donation-page {
    padding: 10px;
  }
}
</style>
